import React from "react";
import { Row, Col } from "react-bootstrap";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import FooterLogo from "./images/footer-logo.png";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer-bg">
      <Row className="footer-content">
        <Col md={3} className="footer-logo text-md-right">
          <img src={FooterLogo} alt="Vyatra Logo" />
        </Col>
        <Col md={6} className="footer-links">
          <div className="links-and-icons">
            <ul className="quick-links">
              <li>
                <a href="/">JOURNEYS</a>
              </li>
              <li>
                <a href="/how-it-works">HOW IT WORKS</a>
              </li>
              <li>
                <a href="/about-us">ABOUT US</a>
              </li>
              <li>
                <a href="/contact">CONTACT</a>
              </li>
            </ul>
            <div className="social-icons">
              <a href="https://facebook.com" target="_blank" rel="noreferrer">
                <FaFacebookF />
              </a>
              <a href="https://instagram.com" target="_blank" rel="noreferrer">
                <FaInstagram />
              </a>
            </div>
          </div>
        </Col>

        <Col md={1}></Col>
      </Row>
      <Row className="footer-bottom">
        <Col md={3}></Col>
        <Col md={6} className="terms">
          <div className="terms-content" style={{paddingTop:'20px'}}>
            <p>© 2024 Vyatra Pvt Ltd. All Rights Reserved</p>
            <a href="/terms-and-conditions">Terms & Conditions</a>
          </div>
        </Col>
        <Col md={1}></Col>
      </Row>
    </div>
  );
};

export default Footer;
