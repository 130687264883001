import React,{useState} from "react";
import { Navbar, Nav, Container, Row, Col,Offcanvas } from "react-bootstrap";
import "./Navbar.css"; // Import custom CSS for background
import logo from "./images/logo.png"; // Update this path

function NavbarPage({title}) {
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const handleClose = () => setShowOffcanvas(false);
  const handleShow = () => setShowOffcanvas(true);
  return (
    <div className="contact-image">
      <Navbar expand="lg" variant="dark" className="transparent-navbar">
        <Container>
          <Navbar.Brand href="/">
            <img
              src={logo}
              height="50" // Adjust height based on your requirement
              alt="Vyatra logo"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleShow} />
          <Navbar.Collapse id="basic-navbar-nav" className="d-none d-lg-flex">
            <Nav className="ms-auto nav-links">
              <Nav.Link href="/">HOME</Nav.Link>
              <Nav.Link href="/how-it-works">HOW IT WORKS</Nav.Link>
              <Nav.Link href="/contact">CONTACT</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Offcanvas show={showOffcanvas} onHide={handleClose} placement="end" >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
          <img
              src={logo}
              height="50" // Adjust height based on your requirement
              alt="Vyatra logo"
              className="d-inline-block align-top"
            />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">
            <Nav.Link href="/" onClick={handleClose}>HOME</Nav.Link>
            <Nav.Link href="/how-it-works" onClick={handleClose}>HOW IT WORKS</Nav.Link>
            <Nav.Link href="/contact" onClick={handleClose}>CONTACT</Nav.Link>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>

      <Container>
        <Row>
          {/* Text Section */}
          <Col md={6} className="contact-text-left">
            <h1>{title}</h1>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default NavbarPage;
