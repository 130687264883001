import React, { useState, useEffect } from "react";
import {
  Navbar,
  Nav,
  Container,
  Offcanvas,
  Row,
  Col,
  Card,
  Button,
} from "react-bootstrap";
import "./Navbar.css"; // Import custom CSS for background
import "./Readmore.css";
import logo from "./images/logo.png"; // Update this path
import African from "./images/africian.png"; // Import your image
import Ganga from "./images/dsd.png";
import kangaroo from "./images/australia_animal.png";
import HinduReligion from "./images/Hindu-religion.png";
import Sabarmati from "./images/The-Saint-of-Sabarmati-India.png";
import Elephant from "./images/elephant.jpg";
import ShogunateJapan from "./images/Shogunate-Japan.png";
import JersualemIsrael from "./images/Jersualem-Israel.png";
import Slik from "./images/slikroad.jpg";
import Jerusalem from "./images/Jerusalem.png";
import Buddha from "./images/buddha.png";
import Sabarmati1 from "./images/The-Saint-of-Sabarmati-India.png";
import Temple from "./images/photo.jpg";
import Emailus from "./Emailus";

import { useNavigate } from "react-router-dom";

const ReadMorePage = () => {
  const [services, setServices] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem("readmore", true)
  }, []);

  const imageMap = {
    African,
    Ganga,
    HinduReligion,
    Sabarmati,
    Elephant,
    ShogunateJapan,
    JersualemIsrael,
    Slik,
    Jerusalem,
    Sabarmati1,
    Temple,
    Buddha,
  };

  const mapServices = [1, 5, 2, 6, 7, 8, 4, 9, 3, 10];

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_LOCAL_URL}/getAllCategory`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setCategories(data);
        } else {
          console.error("Failed to fetch services");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    const fetchServices = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_LOCAL_URL}/getAllServices`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setServices(data);
        } else {
          console.error("Failed to fetch services");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchServices();
    fetchCategories();
  }, []);

  const data = [
    {
      id: 1,
      title: "For Australian Schools",
      text: "Engage with classrooms across the world in real-time.",
      cards: [
        {
          id: 1,
          title: "Year 4 Geography",
          subtitle: "Year 4 Geography The Earth’s Climate",
          description: "African Safari (Kenya)",
          url: "African Safari (Kenya)",
          span1: "AU",
          h1: "$15",
          span2: "per student,",
          span3: "subject to minimum $650 (plus GST)",
          image: African,
        },
        {
          id: 2,
          title: "Year 7 RE Buddhism",
          subtitle: "Year 7 Religious Education",
          description: "In Buddha's Footsteps, India",
          url: "Buddhism",
          span1: "INR",
          h1: "150",
          span2: "per student,",
          span3: "",
          image: Buddha,
        },
        {
          id: 3,
          title: "Year 7 Geography Water in the World",
          subtitle: "Year 7 Geography Water in the World",
          description: "The Ganga River Ecosystem, India",
          url: "Year 7 Geography Water in the World",
          span1: "AU",
          h1: "$15",
          span2: "per student",
          span3: "subject to minimum $650 (plus GST)",
          image: Ganga,
        },
        {
          id: 4,
          title: "Year 7 History Investigating the Ancient Past",
          subtitle: "Year 7 History Investigating the Ancient Past",
          description: "Jersualem, Israel",
          url: "investigating-the-ancient-past",
          span1: "AU",
          h1: "$1,600",
          span2: "per session,",
          span3: "(plus GST)",
          image: JersualemIsrael,
        },
        {
          id: 5,
          title: "Year 8 History The Asia-Pacific World",
          subtitle: "Year 8 History The Asia-Pacific World",
          description: "The Shogunate Japan",
          url: "the-asia-pacific-world",
          span1: "AU",
          h1: "$15",
          span2: "per student,",
          span3: "subject to minimum $750 (plus GST)",
          image: ShogunateJapan,
        },
        {
          id: 6,
          title: "Year 8 RE Hinduism",
          subtitle: "Year 8 RE Hinduism",
          url: "year-8-re-hinduism",
          description: "Mystical Hinduism, India",
          span1: "AU",
          h1: "$15",
          span2: "per student,",
          span3: "subject to minimum $650 (plus GST)",
          image: HinduReligion,
        },
        {
          id: 7,
          title: "Year 9 Geography Geographies of Interconnections",
          subtitle: "Year 9 Geography Geographies of Interconnections",
          description: "The Silk Roads of Samarkand, Uzbekistan",
          url: "geographies-of-interconnections",
          span1: "AU",
          h1: "$15",
          span2: "per student,",
          span3: "subject to minimum $650 (plus GST)",
          image: Slik,
        },
        {
          id: 8,
          title: "Year 9/10 RE Judaism, Christianity",
          subtitle: "Year 9/10 RE Judaism, Christianity",
          description: "The Holy Land - Jerusalem, Israel",
          url: "christianity",
          span1: "AU",
          h1: "$1,600",
          span2: "per session,",
          span3: "(plus GST )",
          image: Jerusalem,
        },
        {
          id: 9,
          title: "Year 10 History Rights & Freedoms",
          subtitle: "Year 10 History Rights & Freedoms",
          description: "Indian Independence Movement: The Saint of Sabarmati",
          url: "rights-freedoms",
          span1: "AU",
          h1: "$15",
          span2: "Per student,",
          span3: "subject to minimum $750 (plus GST)",
          image: Sabarmati,
        },
        {
          id: 10,
          title: "Year 10 Geography Environmental Change & Management",
          subtitle: "Year 10 Geography Environmental Change & Management",
          description: "Ol Kinyei, Masai Mara, Kenya",
          url: "environmental-change-management",
          span1: "AU",
          h1: "$15",
          span2: "Per student,",
          span3: "subject to minimum $750 (plus GST)",
          image: Elephant,
        },
      ],
    },
  ];
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const handleClose = () => setShowOffcanvas(false);
  const handleShow = () => setShowOffcanvas(true);
  const navigate = useNavigate();

  // const handleCardClick = (card) => {
  //     navigate(`/card-detail/${card.id}`, { state: { card } }); // Pass full card object
  //   };
  const handleCardClick = (card) => {
    const formattedDescription = card.url
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[()]/g, ""); // Convert to lowercase, replace spaces with hyphens, remove special characters
    navigate(`/${formattedDescription}`, {
      state: { card, category_id: card.category_id, readmore: true },
    });
  };

  const handleBackToHome = () => {
    navigate("/"); // Navigates to the home page
  };

  const filteredAndSortedServices = mapServices
    .map((serviceId) =>
      services?.find(
        (card) =>
          card.serviceId === serviceId.toString() &&
          parseInt(card.category_id) === 1
      )
    )
    .filter((card) => card !== undefined);
  return (
    <>
      <div className="backgroundread-image">
        <div className="elementor-background-overlay"></div>
        <Navbar expand="lg" variant="dark" className="transparent-navbar">
          <Container>
            <Navbar.Brand href="/">
              <img
                src={logo}
                height="50" // Adjust height based on your requirement
                alt="Vyatra logo"
                className="d-inline-block align-top"
              />
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              onClick={handleShow}
            />
            <Navbar.Collapse id="basic-navbar-nav" className="d-none d-lg-flex">
              <Nav className="ms-auto nav-links">
                <Nav.Link href="/">HOME</Nav.Link>
                <Nav.Link href="/how-it-works">HOW IT WORKS</Nav.Link>
                <Nav.Link href="/contact">CONTACT</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <Offcanvas show={showOffcanvas} onHide={handleClose} placement="end">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              {" "}
              <img
                src={logo}
                height="50" // Adjust height based on your requirement
                alt="Vyatra logo"
                className="d-inline-block align-top"
              />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="flex-column">
              <Nav.Link href="/" onClick={handleClose}>
                HOME
              </Nav.Link>
              <Nav.Link href="/how-it-works" onClick={handleClose}>
                HOW IT WORKS
              </Nav.Link>
              <Nav.Link href="/contact" onClick={handleClose}>
                CONTACT
              </Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Offcanvas>
        <Container className="video">
          <Row>
            <Col md={8} className="CardDetailSubtitle">
              <h1>For Australian Schools</h1>
            </Col>
            <Col md={4}>
              <div className="BackToHome">
                <button className="back-btn" onClick={handleBackToHome}>
                  Back To Home
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <Row
          className="mt-5"
          style={{
            backgroundColor: "#f5f5f5",
            padding: "10px",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {filteredAndSortedServices?.map(
            (
              card // Check if the clicked button has cards to show
            ) => (
              <React.Fragment key={card.id}>
                {/* {item.cards.map((card) => ( */}
                <Col key={card.id} md={3} className="d-flex mt-5">
                  <Card
                    className="card-container"
                    onClick={() => handleCardClick(card)}
                    // Add click handler
                    style={{ cursor: "pointer" }} // Add pointer cursor to show it's clickable
                  >
                    <Card.Img
                      variant="top"
                      src={imageMap[card.image]}
                      alt={card.service_name}
                      style={{ height: "320px", objectFit: "cover" }} // Same image height
                    />
                    <Card.Body>
                      <Card.Title className="card-title">
                        {card.service_name}
                      </Card.Title>
                      <Card.Text className="card-text">
                        <small>{card.description}</small>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                {/* ))} */}
              </React.Fragment>
            )
          )}
        </Row>
      </Container>

      <Emailus />
    </>
  );
};

export default ReadMorePage;
