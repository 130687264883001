import React from "react";
import NavbarPage from "./NavPage";
import ContactForm from "./ContactForm";
import Emailus from "./Emailus";

const Contact = () => {
  return (
    <>
      <NavbarPage title="Contact" />
      <ContactForm />
      <Emailus />
    </>
  );
};

export default Contact;
