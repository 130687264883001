import React, { useState } from "react";
import { Carousel, Container, Row, Col, Form, Button } from "react-bootstrap";
import "./Testimonial.css"; // Custom styles

const testimonials = [
  {
    id: 1,
    message: `The cultural exchange program run by vyatra between St. Karens and Trinity Grammar proved to be a remarkable opportunity for both educational institutions. Personally, I hold a deep affection for India, considering it among my favourite destinations worldwide. Having explored diverse regions such as Delhi, Rajasthan, Mumbai, Varanasi, Bangalore, and Kerala, I've come to appreciate the vast differences that exist within this single country. The opportunity to share facets of India with our boys was an opportunity too valuable to pass up.
 
    I extend my gratitude to Saurabh for affording me and the Year 9 boys of Trinity this opportunity to experience a part of India. His meticulous organisation of the event demonstrated a level of professionalism rarely seen, surpassing the conduct of many external companies that engage with Trinity. The seamless execution of the event facilitated smooth proceedings, and the communication with me was consistently first-class.
     
    Exposure to other cultures is vital for the development of the Trinity boys, aiding them in better understanding themselves, their peers, and their place in the world. The interaction with students from St. Karens provided valuable insights into life in Patna, fostering a deeper appreciation for diverse upbringings.
     
    Rishabh Mishra played a pivotal role in the success of the event, demonstrating remarkable leadership with his fluency in Hindi and familial ties to India. In particular the way in which he helps to create conversation and also to ensure all students understood was invaluable, and I commend him for his significant contribution to the exchange.
     
    Our appreciation extends to St. Karens for extending this opportunity, and the selected students were a true credit to their school. They not only answered Trinity students' questions eloquently but also posed intelligent, thoughtful, and nuanced questions themselves, offering a glimpse into their unique personalities.
     
    To the organisers from St. Karens, a heartfelt thank you for your diligent efforts in ensuring the event's success. It is my sincere hope that this exchange becomes a tradition, a recurring event that we can continue for years to come and perhaps culminating in a face-to-face visit in the future.`,
    name: "James Hale (Rev’d)",
    title: "Assistant Chaplain | Head of Religious Education, Trinity Grammar School",
    // location: "Patna, Bihar, India",
  },
  {
    id: 2,
    message: `The cultural exchange program organized by vyatra was an excellent platform for students.

    The host Mr. Rishabh did a great job in understanding the constraints of the children and adding on points to explain the questions and answers for each side to grasp what was being said. He really deserves appreciation for his extraordinary skill to make everyone feel important.
    
    The participants of our school were happy that they were heard patiently and were given ample time to interact.
    
    We are grateful to the vyatra team for this opportunity for our students.
    
    Students were excited and happy to interact with foreign students. For most of them it was a first time experience. This sharing of ideas, through an online platform helped the students gain confidence and hone their communication skills. It was also a step to know, understand and appreciate the culture of Australia and India for the partner schools.
    
    Regards,`,
    name: " Marrykutty Thomas",
    title: "Principal, St. Karen’s Secondary School",
    // location: "Patna, Bihar, India",
  },
  {
    id: 3,
    message: `I would also like to extend a congratulatory handshake to you from across the miles!

    I am greatly appreciative of this initiative of yours to expose students to their counterparts from all over the world .It has opened up a myriad of possibilities and our first experience of a vyatra was enriching!
    
    Please do convey my Congratulations to Rishi for adeptly handling the session.Due credit goes to him for expertly managing the interaction between the two sides and keeping it entertaining as well.
    
    I’m sure the team at vyatra will curate many more interesting sessions for us at St Karen’s and I’m eagerly looking forward to us meeting virtually again soon.
    
    Warm regards,`,
    name: "Sharon Galstaun",
    title: "Joint Director, The Anglo-Indian Educational Society of St Karen’s",
    // location: "Patna, Bihar, India",
  },
  {
    id:4,
    message:`The cultural exchange program organized by vyatra was an excellent platform for students.

    The host Mr. Rishabh did a great job in understanding the constraints of the children and adding on points to explain the questions and answers for each side to grasp what was being said. He really deserves appreciation for his extraordinary skill to make everyone feel important.
    
    The participants of our school were happy that they were heard patiently and were given ample time to interact.
    
    We are grateful to the vyatra team for this opportunity for our students.
    
    Students were excited and happy to interact with foreign students. For most of them it was a first time experience. This sharing of ideas, through an online platform helped the students gain confidence and hone their communication skills. It was also a step to know, understand and appreciate the culture of Australia and India for the partner schools.
    
    Regards,`,
    name:' Edward Galstaun',
    title:' Joint Director, The Anglo-Indian Educational Society of St Karen’s',
    // location:' Patna, Bihar, India'
  },
  {
    id:5,
    message:`On behalf of the entire St. Karen's family, I extend my hearƞelt appreciation to Mr Saurabh and all other organisers and also each and every participant of Trinity Grammar School, Melbourne,
    Australia for the unparalleled experience offered by the VYATRA programme. The initiative has been truly exceptional, marking it as one of its kind for all participating students. Would like
    to share the key take-aways of the programme with all.
    1. Enriching and Real Experience: The program has undeniably been an enriching experience for our students. The cultural exchange, coupled with insights into the daily lives of students
    from Trinity Grammar School, has added a layer of understanding and appreciation that textbooks cannot provide. It was amazing to see the students interacting live which each other and watching the games directly as well as looking at classroom ambience of either side.
    2. Foundation for the development of global citizenship: The program has proven to be instrumental in bridging gaps of doubts and fears about each other's cultures. It serves as a foundation for the development of global citizenship, fostering an environment of mutual respect and understanding.
    3. Prospects for Future Exchanges: VYATRA has opened avenues for future exchanges between our schools. These exchanges present exciting prospects for sharing not only academic experiences but also sports-related endeavours. Such collaborations can foster a deeper connection and understanding between our students.
    4. Technological Connection for Innovation: The use of technology in the VYATRA program has been instrumental in facilitating the sharing of ideas and fostering innovation. It has served as a plaƞorm for students to exchange thoughts, invent new concepts, and indulge in stress-busting activities. The freshness in these connections has infused happy vibes and excitement among the participants.
    
    In conclusion, the VYATRA program has not only been a success but has set a precedent for future collaborative initiatives. St. Karen's High School looks forward to further engaging with
    Trinity Grammar School and similar institutions, fostering an environment where education goes beyond borders and embraces a truly global perspective.
    
    Saurabh a special thanks to you for sure making it so natural, interesting and allowing the students to feel free and relaxed during the interaction.
    
    Sincerely,  `,
    name:'Seema Singh',
    title:`Principal, St. Karen's High School`,
    // location:' Patna, Bihar, India'

  }

];

const TestimonialPage = () => {
  return (
    <>
      <Container className="testimonial-page ">
        <h2 className="text-center mt-4">Testimonials</h2>
        <Carousel interval={3000} pause="hover">
          {testimonials.map((testimonial) => (
            <Carousel.Item key={testimonial.id}>
              <div className="testimonial-slide">
                <p className="testimonial-message">{testimonial.message}</p>
                <p className="testimonial-author">
                  <strong>{testimonial.name}</strong>
                  <br />
                  {testimonial.title}
                  <br />
                  <small>{testimonial.location}</small>
                </p>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>
    </>
  );
};

export default TestimonialPage;
