import React from 'react';
import HowWorks from '../HowWorks';
import NavbarPage from '../NavPage';
import Emailus from "../Emailus";

function HowItWorks() {
  return (
   <>
    <NavbarPage title="How It Works" />
     <HowWorks />
     <Emailus />
    </>
  );
}

export default HowItWorks;
