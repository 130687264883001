import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Navbar,
  Nav,
  Form,
  Container,
  Row,
  Col,
  Button,
  Offcanvas,
  Modal,
} from "react-bootstrap";
import logo from "./images/logo.png";
import TestimonialPage from "./Testimonial";
import Emailus from "./Emailus";
import { loadStripe } from "@stripe/stripe-js";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import African from "./images/africian.png"; // Import your image
import Ganga from "./images/dsd.png";
import kangaroo from "./images/australia_animal.png";
import Temple from "./images/photo.jpg";
import GangaRiver from "./images/gangariver.jpg";
import Sabarmati from "./images/sabarmati.jpg";
import Sabarmati1 from "./images/The-Saint-of-Sabarmati-India.png";
import Akshardham from "./images/akshardham.jpg";
import Buddhism from "./images/buddhism.webp";
import Japan from "./images/japan-the-world.png";
import Ancient from "./images/8-gl-03.jpg";
import Holyland from "./images/holyland.png";
import Environment from "./images/environment.jpg";
import Slik from "./images/slikroad.jpg";
import HinduReligion from "./images/Hindu-religion.png";
import Elephant from "./images/elephant.jpg";
import ShogunateJapan from "./images/Shogunate-Japan.png";
import JersualemIsrael from "./images/Jersualem-Israel.png";
import Jerusalem from "./images/Jerusalem.png";
import Buddha from "./images/buddha.png";

const stripePromise = loadStripe(
  "pk_test_51QCJUxHMKDTIncDZDA3oOxsThAdz6CKk5SaZDnTe9E5ZcnuywYfBckUX2T9u4m5hyIzGCwwVzpJbxIzsDhyRpAHK00lPSPYKQd"
);

const BookSession = ({ cardData }) => {
  const navigate = useNavigate();
  const location = useLocation();
  // const { card, category_id } = location.state;
  const [intent, setIntent] = useState("");
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [payAmount, setPayAmount] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShowOffcanvas(false);
  const handleShow = () => setShowOffcanvas(true);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    contact: "",
    school_name: "",
    serviceId: cardData.serviceId,
    category_id: cardData?.category_id,
    status: "Pending",
    date: "",
    comment: "",
    number_of_students: "",
  });

  const imageMap = {
    Slik,
    African,
    Ganga,
    Sabarmati1,
    Temple,
    GangaRiver,
    Sabarmati,
    Akshardham,
    Buddhism,
    Japan,
    Ancient,
    Holyland,
    Environment,
    HinduReligion,
    Sabarmati,
    Elephant,
    ShogunateJapan,
    JersualemIsrael,
    Slik,
    Jerusalem,
    Buddha,
  };

  const stripe = useStripe();
  const elements = useElements();

  const [errors, setErrors] = useState({
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "number_of_students") {
      const amt = value && Number(cardData?.amount) * parseInt(value);
      setPayAmount(amt);
    }

    let newErrors = { ...errors };
    if (name === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      newErrors.email = !emailRegex.test(value)
        ? "Please enter a valid email address"
        : "";
    } else if (
      (name === "contact" || name === "number_of_students") &&
      value === ""
    ) {
      newErrors[name] = "This field is required";
    } else {
      newErrors[name] = "";
    }

    setErrors(newErrors);
  };

  const validateContact = () => {
    const contact = formData.contact.trim();
    let newErrors = { ...errors };

    if (contact.length < 7 || contact.length > 15) {
      newErrors.contact = "Contact number must be between 7 and 15 digits.";
    } else {
      newErrors.contact = "";
    }

    setErrors(newErrors);
  };

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = async () => {
    let newErrors = { ...errors };
    let hasErrors = false;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      newErrors.email = "Please enter a valid email address";
      hasErrors = true;
    } else {
      newErrors.email = "";
    }

    if (!formData.contact) {
      newErrors.contact = "Contact is required";
      hasErrors = true;
    } else if (formData.contact.length < 7 || formData.contact.length > 15) {
      newErrors.contact = "Contact number must be between 7 and 15 digits.";
      hasErrors = true;
    } else {
      newErrors.contact = "";
    }

    if (!formData.number_of_students) {
      newErrors.number_of_students = "Number of students is required";
      hasErrors = true;
    }

    setErrors(newErrors);

    if (hasErrors) {
      setLoading(false);
      return;
    }

    if (!hasErrors) {
      setShowModal(true);
    }

    if (!stripe || !elements) {
      setErrorMessage("Stripe is not initialized.");
      setLoading(false);
      return;
    }
    try {
      const submitResult = await elements.submit();
      if (submitResult.error) {
        setErrorMessage(submitResult.error.message);
        setLoading(false);
        return;
      }

      const response = await fetch(
        `${process.env.REACT_APP_LOCAL_URL}/createIntent`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...formData }),
        }
      );

      const responseData = await response.json();
      setIntent(responseData);
    } catch (error) {
      console.error("Payment error:", error);
      setLoading(false);
      setErrorMessage(`Payment failed: ${error.message}`);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      setErrorMessage("Stripe is not initialized.");
      setLoading(false);
      return;
    }

    try {
      if (!intent.client_secret) {
        console.log("Client secret not received from server");
      } else {
        console.log("Client secret received from server");
      }

      // const { error, paymentIntent } = await stripe.confirmPayment({
      //   elements,
      //   clientSecret: intent.client_secret,
      //   confirmParams: {
      //     payment_method: 'card',
      //     return_url: `${process.env.REACT_APP_LOCAL_URL}/getPaymentDetailsByIntent/${intent.client_secret}`,
      //   },
      // });

      const { paymentIntent, error } = await stripe.confirmCardPayment(
        intent.client_secret,
        {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              name: "Jenny Rosen",
            },
          },
        }
      );

      console.log(paymentIntent);
      if (error) {
        setErrorMessage(error.message);
        setLoading(false);
      } else if (paymentIntent && paymentIntent.status === "succeeded") {
        setSuccessMessage("Payment successful!");
        setLoading(false);
        setFormData({
          customer_name: "",
          email: "",
          contact: "",
          school_name: "",
          serviceId: cardData?.serviceId,
          category_id: cardData?.category_id,
          status: "Pending",
          date: "",
          comment: "",
          number_of_students: "",
        });
        await fetch(
          `${process.env.REACT_APP_LOCAL_URL}/getPaymentDetailsByIntentByCardElement`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(paymentIntent),
          }
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json(); // assuming the response is in JSON format
          })
          .then((data) => {
            console.log("Payment details received:", data);
            navigate(`/payment-status/${data}`);
          })
          .catch((error) => {
            console.error(
              "There was a problem with the fetch operation:",
              error
            );
          });
      }
    } catch (error) {
      console.error("Payment error:", error);
      setLoading(false);
      setErrorMessage(`Payment failed: ${error.message}`);
    }
  };

  const getDescriptionPrefix = (description) => {
    if (description === "African Safari (Kenya)") {
      return "Educational Journey - African Safari (kenya)";
    } else if (description === "Ganga River Ecosystem, India") {
      return "Educational Journey - Water in the world ";
    } else if (description === "In Buddha's Footsteps, India") {
      return "Showcase India To the World -";
    } else if (description === "The Ganga River Ecosystem, India") {
      return "Educational Journey - Water in the world ";
    } else if (description === "Jersualem, Israel") {
      return "Educational Journey - Investigating the Ancient Past";
    } else if (description === "The Shogunate Japan") {
      return "Educational Journey - The Asia-Pacific World";
    } else if (description === "Mystical Hinduism, India") {
      return "Educational Journey -Year 8 RE Hinduism";
    } else if (description === "The Silk Roads of Samarkand, Uzbekistan") {
      return "Educational Journey - Year 9 Geography, Geographies of Interconnections";
    } else if (description === "The Holy Land - Jerusalem, Israel") {
      return "Educational Journey - Christianity /Judaism";
    } else if (
      description === "Indian Independence Movement: The Saint of Sabarmati"
    ) {
      return "Educational Journey - Right & Freedoms";
    } else if (description === "Ol Kinyei, Masai Mara, Kenya") {
      return "Educational Journey - Environmental Change & Management";
    } else if (description === "The Ganga River Ecosystem") {
      return "Showcase India To the World -";
    } else if (description === "The Saint of Sabarmati") {
      return "Showcase India To the World -";
    } else if (
      description === "Indian Independence Movement: The Saint of Sabarmati"
    ) {
      return "Showcase India To the World -";
    } else if (description === "In the Buddha’s Footsteps") {
      return "Showcase India To the World -";
    } else if (description === "Shogunate Japan") {
      return "Showcase Japan To the World -";
    } else if (description === "Investigating the Ancient Past") {
      return "Showcase Holy Land To the World -";
    } else if (description === "Environmental Change & Management") {
      return "Showcase Kenya To the World -";
    } else if (description === "The Silk Roads") {
      return "Showcase Uzbekistan to the world! - ";
    } else if (description === "Mystical Hinduism") {
      return "Showcase India To the World -";
    } else if (description === "The Holy Land")
      return "Showcase Israeli to the world! -";

    // Add more conditions as needed
    return ""; // Default case if no match found
  };

  <span style={{ color: "#ff5722" }}>
    {getDescriptionPrefix(cardData?.description)}
  </span>;
  const today = new Date().toISOString().split("T")[0];

  return (
    <>
      <div
        className="backgroundcard-image"
        style={{ backgroundImage: `url(${imageMap[cardData?.image]})` }}
      >
        <div className="elementor-background-overlay"></div>
        <Navbar expand="lg" variant="dark" className="transparent-navbar">
          <Container>
            <Navbar.Brand href="/">
              <img
                src={logo}
                height="50" // Adjust height based on your requirement
                alt="Vyatra logo"
                className="d-inline-block align-top"
              />
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              onClick={handleShow}
            />
            <Navbar.Collapse id="basic-navbar-nav" className="d-none d-lg-flex">
              <Nav className="ms-auto nav-links">
                <Nav.Link href="/">HOME</Nav.Link>
                <Nav.Link href="/how-it-works">HOW IT WORKS</Nav.Link>
                <Nav.Link href="/contact">CONTACT</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Offcanvas show={showOffcanvas} onHide={handleClose} placement="end">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              {" "}
              <img
                src={logo}
                height="50" // Adjust height based on your requirement
                alt="Vyatra logo"
                className="d-inline-block align-top"
              />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="flex-column">
              <Nav.Link href="/" onClick={handleClose}>
                HOME
              </Nav.Link>
              <Nav.Link href="/how-it-works" onClick={handleClose}>
                HOW IT WORKS
              </Nav.Link>
              <Nav.Link href="/contact" onClick={handleClose}>
                CONTACT
              </Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Offcanvas>
        <Container>
          <Row>
            <Col md={8} className="CardDetailSubtitle">
              <h1>
                {cardData?.description === "Jersualem, Israel"
                  ? "Investigating The Ancient Past"
                  : cardData?.description === "The Shogunate Japan"
                  ? "The Asia-Pacific World"
                  : cardData?.service_subname}
              </h1>
              <p style={{ color: "white" }}>
                {cardData?.description === "Jersualem, Israel"
                  ? "The Holy Land – Jersualem, Israel"
                  : cardData?.description}
              </p>
            </Col>
            <Col md={4}>
              {/* {card.description === "The Holy Land" ? null : ( */}
              <div className="CardDetailContent">
                <h4 style={{ color: "white" }}>Price</h4>
                {/* Only render price details if all fields are present */}
                <div className="count-num">
                  {cardData?.currency && (
                    <span>
                      {/* {card.description === "Investigating the Ancient Past"
                        ? "s"
                        : card?.currency} */}
                      {cardData?.currency}
                    </span>
                  )}
                  {cardData?.amount && (
                    <h1>
                      {cardData.category_id === "1" &&
                      cardData.currency === "AU"
                        ? `$${Number(cardData?.amount)}`
                        : Number(cardData?.amount)}
                      {/* {card.description === "Investigating the Ancient Past"
                        ? "24"
                        : card.category_id === "1" && card.currency === "AU"
                        ? `$${Number(card?.amount)}`
                        : Number(card?.amount)} */}
                    </h1>
                  )}
                  {cardData?.students && <span>{cardData?.students}</span>}
                  {cardData?.subject_to && (
                    <span className="pb-sub">
                      {cardData?.subject_to}
                      {/* {card.description === "Ol Kinyei, Masai Mara, Kenya"
                        ? "subject to minimum $650 (plus GST)"
                        : card?.subject_to} */}
                    </span>
                  )}
                </div>
              </div>
              {/* )} */}
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <Col md={6}>
            <h2 style={{ paddingTop: "20px" }}>
              {cardData.description === "In Buddha's Footsteps, India"
                ? "Showcase India to the World"
                : cardData.description === "The Ganga River Ecosystem, India" &&
                  localStorage.getItem("readmore") === "true"
                ? "Water in the World"
                : cardData.description === "Jersualem, Israel"
                ? "Investigating the Ancient Past"
                : cardData.description === "The Shogunate Japan"
                ? "The Asia-Pacific World"
                : cardData.description === "Mystical Hinduism, India"
                ? "Year 8 RE Hinduism"
                : cardData.description ===
                  "The Silk Roads of Samarkand, Uzbekistan"
                ? "Geographies of Interconnections"
                : cardData.description === "The Holy Land - Jerusalem, Israel"
                ? "Christianity/Judaism"
                : cardData.description ===
                  "Indian Independence Movement: The Saint of Sabarmati"
                ? "Rights & Freedoms"
                : cardData.description === "Ol Kinyei, Masai Mara, Kenya"
                ? "Environmental Change & Management"
                : cardData.description === "The Ganga River Ecosystem" ||
                  cardData.description === "The Saint of Sabarmati" ||
                  cardData.description === "Mystical Hinduism" ||
                  cardData.description === "In the Buddha’s Footsteps"
                ? "Showcase India to the World"
                : cardData.description === "Shogunate Japan"
                ? "Showcase Japan to the world!"
                : cardData.description === "Investigating the Ancient Past"
                ? "Showcase Israeli to the world!"
                : cardData.description === "Environmental Change & Management"
                ? "Showcase Kenya to the world!"
                : cardData.description === "The Silk Roads"
                ? "Showcase Uzbekistan to the world!"
                : cardData.description === "The Holy Land"
                ? "Showcase Israeli to the world!"
                : cardData.description}
            </h2>

            <p>
              Great! We are equally excited about bringing this
              <span style={{ color: "#ff5722" }}>
                {" "}
                {getDescriptionPrefix(cardData.description)}
              </span>{" "}
              to you. Please let us know a little about your school and
              requirements. One of our vyatra session leads will be in touch
              shortly.{" "}
            </p>
            <p>
              "<span style={{ color: "red" }}>*</span>" indicates required
              fields
            </p>

            <div onSubmit={handleShowModal}>
              {successMessage && (
                <p
                  style={{ color: "green", fontWeight: "bold" }}
                  className="success-message"
                >
                  {successMessage}
                </p>
              )}
              {errorMessage && (
                <p
                  style={{ color: "red", fontWeight: "bold" }}
                  className="error-message"
                >
                  {errorMessage}
                </p>
              )}

              <div controlId="first_name" style={{ marginTop: "15px" }}>
                <h6>First Name</h6>
                <Form.Control
                  type="first_name"
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleChange}
                  className={`form-input ${
                    errors.first_name ? "is-invalid" : ""
                  }`}
                />
              </div>

              <div controlId="last_name" style={{ marginTop: "15px" }}>
                <h6>Last Name</h6>
                <Form.Control
                  type="last_name"
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleChange}
                  className={`form-input ${
                    errors.last_name ? "is-invalid" : ""
                  }`}
                />
              </div>

              <div controlId="email" style={{ marginTop: "15px" }}>
                <h6>
                  Email <span style={{ color: "red" }}>*</span>
                </h6>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className={`form-input ${errors.email ? "is-invalid" : ""}`}
                />
                {errors.email && (
                  <div style={{ color: "red" }}>{errors.email}</div>
                )}
              </div>

              <div controlId="contact" style={{ marginTop: "15px" }}>
                <h6>
                  Contact <span style={{ color: "red" }}>*</span>
                </h6>
                <Form.Control
                  type="number"
                  name="contact"
                  value={formData.contact}
                  onChange={handleChange}
                  onBlur={validateContact}
                />
                {errors.contact && (
                  <div style={{ color: "red" }}>{errors.contact}</div>
                )}
              </div>

              <div controlId="school_name" style={{ marginTop: "15px" }}>
                <h6>School Name</h6>
                <Form.Control
                  type="text"
                  name="school_name"
                  value={formData.school_name}
                  onChange={handleChange}
                />
              </div>

              <div controlId="number_of_students" style={{ marginTop: "15px" }}>
                <h6>
                  Number of Students <span style={{ color: "red" }}>*</span>
                </h6>
                <Form.Control
                  type="number"
                  name="number_of_students"
                  value={formData.number_of_students}
                  onChange={handleChange}
                />
                {errors.number_of_students && (
                  <div style={{ color: "red" }}>
                    {errors.number_of_students}
                  </div>
                )}
              </div>

              <div controlId="date" style={{ marginTop: "15px" }}>
                <h6>
                  Date (choose a date you would like to schedule your journey)
                </h6>
                <Form.Control
                  type="date"
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                  min={today}
                  onClick={(e) => e.target.showPicker()} // Explicitly trigger the datepicker
                />
              </div>

              <div controlId="comment" style={{ marginTop: "15px" }}>
                <h6>Comment</h6>
                <Form.Control
                  placeholder="Please outline any specific requirements"
                  as="textarea"
                  name="comment"
                  value={formData.comment}
                  onChange={handleChange}
                  rows={3}
                />
              </div>

              <Button
                variant="primary"
                style={{
                  backgroundColor: "#C85C0E",
                  color: "white",
                  margin: "10px 0px 70px 0px",
                }}
                type="submit"
                disabled={loading}
                onClick={handleShowModal}
              >
                Pay{" "}
                {payAmount &&
                  !isNaN(Number(payAmount)) &&
                  `(${payAmount} ${cardData.currency})`}
              </Button>
            </div>
          </Col>
          <Col md={6}>
            <TestimonialPage />
          </Col>
        </Row>
      </Container>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Payment Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CardElement />
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              display: "flex",
              flexDirection: window.innerWidth >= 768 ? "row" : "column",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button
              style={{ marginTop: window.innerWidth <= 768 && 10 }}
              variant="primary"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? "Processing..." : "Confirm Payment"}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Emailus />
    </>
  );
};

export default BookSession;
