import React, { useEffect, useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import "./Contact.css"; // Custom CSS for styling
import { FaFacebookF, FaYoutube, FaInstagram } from "react-icons/fa";
import axios from "axios"; // Or use fetch if you prefer

const ContactForm = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    phone: "",
    enquiry_type: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    full_name: "",
    email: ""
  });

  const [successMessage, setSuccessMessage] = useState(""); // State for success message
  const [status, setStatus] = useState(""); // State for success message

  // Handle form input change
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update formData with new values
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));

    // Validation logic
    let newErrors = { ...errors };

    if (name === "full_name") {
      newErrors.full_name = value.length < 3 ? "Full Name must be at least 3 characters" : "";
    }

    if (name === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      newErrors.email = !emailRegex.test(value) ? "Please enter a valid email address" : "";
    }

    setErrors(newErrors); // Update errors state
  };


  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
  
    // Validation check
    let newErrors = { ...errors };
    let hasErrors = false;
  
    // Validate full_name
    if (formData.full_name.length < 3) {
      newErrors.full_name = "Full Name must be at least 3 characters";
      hasErrors = true;
    } else {
      newErrors.full_name = ""; // Clear error if valid
    }
  
    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      newErrors.email = "Please enter a valid email address";
      hasErrors = true;
    } else {
      newErrors.email = ""; // Clear error if valid
    }
  
    setErrors(newErrors); // Update errors state
  
    if (hasErrors) {
      return; // Exit if there are validation errors
    }
  
    // Create a new FormData instance
    const formDataToSend = new FormData();
  
    // Append form data to the FormData instance
    formDataToSend.append("full_name", formData.full_name);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("phone", formData.phone);
    formDataToSend.append("enquiry_type", formData.enquiry_type);
    formDataToSend.append("message", formData.message);
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/add_enquiry`, formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data", // Set the content type to multipart/form-data
        },
      });
      console.log("Form submitted successfully:", response.data);
      setStatus(response.data.status)
      setSuccessMessage(response.data.message)
  
      // Clear the form
      setFormData({
        full_name: "",
        email: "",
        phone: "",
        enquiry_type: "",
        message: "",
      });
      setErrors({ full_name: "", email: "" }); // Clear errors on successful submission
    } catch (error) {
      console.error("Error submitting form:", error);
      // Optionally show an error message
    }
  };
  

  return (
    <>
      <Container className="mt-5 mb-5">
        <Row>
          <Col md={6} style={{ marginTop: 20 }}>
            <h2 className="getIn">Get in Touch</h2>
            <p className="form-p">
              Please fill out this brief form and we promise to get back with
              lightning speed.
            </p>
            <Form onSubmit={handleSubmit}>
              {successMessage && <p style={{ color: 'green', fontWeight: 'bold', color: status === 'success' ? 'green' : 'red' }}>{successMessage}</p>} {/* Show success message */}

              <Form.Group controlId="formFullName">
                <Form.Label className="form-label">Full Name</Form.Label>
                <Form.Control
                  type="text"
                  name="full_name"
                  value={formData.full_name}
                  onChange={handleChange}
                  className={`form-input ${errors.full_name ? 'is-invalid' : ''}`}
                />
                {errors.full_name && <div style={{color: 'red'}} >{errors.full_name}</div>}
              </Form.Group>

              <Form.Group controlId="formEmail" className="mt-3">
                <Form.Label>Email <span style={{ color: 'red' }}>*</span></Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className={`form-input ${errors.email ? 'is-invalid' : ''}`}
                  
                />
                {errors.email && <div style={{color: 'red'}} >{errors.email}</div>}
              </Form.Group>


              <Form.Group controlId="formPhone" className="mt-3">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="form-input"
                />
              </Form.Group>

              <Form.Group controlId="formDropdown" className="mt-3">
                <Form.Label>Enquiry Type</Form.Label>
                <Form.Control
                  as="select"
                  name="enquiry_type"
                  value={formData.enquiry_type}
                  onChange={handleChange}
                  className="form-input custom-select" // Apply the custom class here
                >
                  <option value=""></option>
                  <option value="Enquiry about vyatra journeys">Enquiry about vyatra journeys</option>
                  <option value="Enquiry about commercial partnerships">
                    Enquiry about commercial partnerships
    </option>
                  <option value="Others">Others</option>
                </Form.Control>
              </Form.Group>


              <Form.Group controlId="formMessage" className="mt-3">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  type="text"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  className="form-input"
                />
              </Form.Group>

              <Button variant="none" type="submit" className="mt-1 form-btn">
                Submit
              </Button>
            </Form>
          </Col>
          <Col md={6} style={{ marginTop: 20 }}>
            <h2 className="emailus">Email Us</h2>
            <p
              style={{
                color: "#7A7E94",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "20px",
                fontFamily: 'Poppins", Sans-serif',
              }}
            >
              Have a question? Drop us a line.
            </p>
            <p className="support-vyatra">support@vyatra.com</p>

            <h2>Socials</h2>
            <p
              style={{
                color: "#7A7E94",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "20px",
                fontFamily: 'Poppins", Sans-serif',
              }}
            >
              Check out our social sites.
            </p>

            <div className="elementor-element elementor-element-e6d8d33 elementor-icon-list--layout-inline pb-icon elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="e6d8d33" data-element_type="widget" data-widget_type="icon-list.default">
              <div className="elementor-widget-container">
                <ul className="elementor-icon-list-items elementor-inline-items" style={{ listStyle: 'none' }}>
                  <li className="elementor-icon-list-item elementor-inline-item">
                    <a href="https://www.facebook.com/virtualyatra/" target="_blank" rel="noopener">
                      <FaFacebookF style={{ color: 'white', marginTop: '-10px' }} />
                    </a>
                  </li>
                  <li className="elementor-icon-list-item elementor-inline-item">
                    <a href="https://www.youtube.com/channel/UCwryh2QeHWFtelFbDMnjsDQ" target="_blank" rel="noopener">
                      <FaYoutube style={{ color: 'white', marginTop: '-10px' }} />
                    </a>
                  </li>
                  <li className="elementor-icon-list-item elementor-inline-item">
                    <a href="https://www.instagram.com/vyatraonline/" target="_blank" rel="noopener">
                      <FaInstagram style={{ color: 'white', marginTop: '-10px' }} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContactForm;
