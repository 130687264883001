import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useParams } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import BookSession from "./BookSession";
import './Components/BookSession.css'

const BookSessionForm = (props) => {
  const [cardData, setCardData] = useState({});

  const { url } = useParams();
  console.log(cardData);
  useEffect(() => {
    const getUrlName = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_LOCAL_URL}/getUrlName`,
          {
            method: "POSt",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(url),
          }
        );

        if (response.ok) {
          const data = await response.json();
          setCardData(data);
        } else {
          console.error("Failed to fetch service data");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    getUrlName();
  }, [url]);

  const currency = {
    AU: "aud",
    INR: "inr",
    JPY: "jpy",
    s: "ils",
    Ksh: "kes",
    som: "uzs",
  };

  const options = {
    mode: "payment",
    amount: Number(cardData?.amount) * 100,
    currency: currency[cardData?.currency],
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };

  console.log(options);
  // const options = {
  //   mode: 'payment',
  //   amount: 1099,
  //   currency: 'usd',
  //   // Fully customizable with appearance API.
  //   appearance: {
  //     /*...*/
  //   },
  // };
  console.log(options);

  const stripePromise = loadStripe(
    "pk_test_51QCJUxHMKDTIncDZDA3oOxsThAdz6CKk5SaZDnTe9E5ZcnuywYfBckUX2T9u4m5hyIzGCwwVzpJbxIzsDhyRpAHK00lPSPYKQd"
  );
  // process.env.VITE_STRIPE_PK is the publishable key you can either directly paste your stripe key here but not recommend if you are planning to upload the code on github as it should remain only available to you or save the key in .env file

  return cardData?.amount && cardData?.currency ? (
    <div>
      <Elements stripe={stripePromise} options={options}>
        <BookSession cardData={cardData} />
      </Elements>
    </div>
  ) : (
    <div className="loader-container">
      <div className="spinner"></div>
    </div>
  );
};

export default BookSessionForm;
