import React from "react";
import "./PaymentStatus.css"; // Create a CSS file for styles
import { useParams } from "react-router-dom";

const PaymentStatus = () => {
  const { status } = useParams();
  const renderIcon = () => {
    if (status === "Completed") {
      return (
        <div className="icon correct">
          <svg
            width="60"
            height="60"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="12" fill="#008000" />
            <path
              d="M16.5 8.5L10.5 14.5L7.5 11.5"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      );
    } else {
      return (
        <div className="icon wrong">
          <svg
            width="60"
            height="60"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="12" fill="#FF0000" />
            <path
              d="M8 8L16 16"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M8 16L16 8"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </div>
      );
    }
  };

  const renderConfetti = () => {
    if (status === "Completed") {
      const colors = ["#ffce54", "#4a90e2", "#e94e77", "#f5a623", "#8bc34a"];
      const shapes = ["shape-square", "shape-rectangle", "shape-strip"];

      return Array.from({ length: 20 }).map((_, i) => {
        const color = colors[Math.floor(Math.random() * colors.length)];
        const shape = shapes[Math.floor(Math.random() * shapes.length)];
        const top = Math.random() * -100;
        const left = Math.random() * 100;
        const duration = 3 + Math.random() * 5;
        const delay = Math.random() * 2;
        const rotate = Math.random() * 360;

        return (
          <div
            key={i}
            className={`confetti ${shape}`}
            style={{
              top: `${top}px`,
              left: `${left}vw`,
              backgroundColor: color,
              animationDuration: `${duration}s`,
              animationDelay: `${delay}s`,
              transform: `rotate(${rotate}deg)`,
            }}
          ></div>
        );
      });
    }
    return null;
  };

  return (
    <div className="payment-status"  style={{height: window.innerHeight, display: 'flex', alignItems: 'center'}}>
      <div className="container">
        {renderIcon()}
        <div className="status-message">
          {status === "Completed" ? "Payment succeeded!" : "Payment failed!"}
        </div>
        <div className="details">
          {status === "Completed"
            ? "Thank you for your payment. Your payment has been processed successfully."
            : "Please check your payment details and try again."}
        </div>
        <a href="/" className="button">
          Go to Home page
        </a>
      </div>
      {renderConfetti()}
    </div>
  );
};

export default PaymentStatus;
