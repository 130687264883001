import React from "react";
import NavbarPage from "../NavbarPage";
import ButtonCardPage from "../ButtonCardPage";
import TestimonialPage from "../Testimonial";
import Emailus from "../Emailus";

const Home = () => {
  return (
    <>
      <NavbarPage />
      <ButtonCardPage />
      <TestimonialPage />
      <Emailus />
    </>
  );
};

export default Home;
