import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import './HowWorks.css';  // For custom styles
import Calendar from './images/calender.png';
import Setting from './images/setting.png';
import People from './images/people.png';  // Import the people image
import Kiran from './images/kiran.png';
import Nihal from './images/nihal.png';
import Jarad from './images/jarad.png';
import Accordion from 'react-bootstrap/Accordion';
import { useNavigate } from "react-router-dom";

const HowWorks = () => {
    const navigate = useNavigate();
    const handleNavigate = () =>{
        navigate('/contact')
    }
    return (
        <>
            <Container className="mt-5">
                <Row className="justify-content-center">
                    {/* First Card */}
                    <Col md={6} lg={6} className="mb-4">
                        <Card className="custom-card h-100">
                            <Card.Body>
                                <div className="d-flex align-items-start">
                                    <div className="icon-number">
                                        <span className="number">1</span>
                                    </div>
                                    <img
                                        src={Calendar}
                                        alt="Calendar"
                                        className="icon ms-auto"
                                    />
                                </div>
                                <Card.Title className="mt-3 heading-title" >Book your Journey</Card.Title>
                                <Card.Text className="heading-text">
                                    Tell us which journey you would like to run, and your preferred dates and times for each of your classes (bearing in mind time differences from the destination).
                                </Card.Text>
                                <ul className="heading-text" >
                                    <li>Provide your name, school name and e-mail ID</li>
                                    <li>Pay online to confirm</li>
                                    <li>Our Session Coordinator will send you a presentation slide deck, suggested speaker notes, and pre-read materials for students.</li>
                                </ul>
                            </Card.Body>
                        </Card>
                    </Col>

                    {/* Second Card */}
                    <Col md={6} lg={6} className="mb-4">
                        <Card className="custom-card h-100">
                            <Card.Body>
                                <div className="d-flex align-items-start">
                                    <div className="icon-number">
                                        <span className="number">2</span>
                                    </div>
                                    <img
                                        src={Setting}
                                        alt="Setting"
                                        className="icon ms-auto"
                                    />
                                </div>
                                <Card.Title className="mt-3 heading-title">Two-weeks Before the Sessions</Card.Title>
                              
                                <ul className="heading-text">
                                    <li> Our Session Coordinator will re-confirm timings with you and provide details of the participating school/students from the destination country.</li>
                                    <li>Let us know whether you would like to use your school’s online meeting link (i.e. Zoom/Teams/Google Meet link), or ours</li>
                                </ul>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                {/* Third Card */}
                <Row className="justify-content-center">
                    <Col md={12} className="mb-4">
                        <Card className="custom-card h-100">
                            <Card.Body>
                                <Row className="align-items-center">
                                    <Col md={6}>
                                        <div className="d-flex align-items-start">
                                            <div className="icon-number">
                                                <span className="number">3</span>
                                            </div>
                                        </div>
                                        <Card.Title className="mt-3 heading-title">On the Day</Card.Title>
                                        
                                        <ul>
                                            <li>Start presenting at the start time!
Our Session Coordinator, Guides and participating school students from the destination country will be ready to do their respective presentations when you invite them</li>
                                            
                                        </ul>
                                        <Button variant="primary" onClick={handleNavigate} className="getTouch-btn mt-3">Get in Touch</Button>
                                    </Col>
                                    <Col md={6} className="text-end">
                                        <img src={People} alt="Local Guides" className="people-image" />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <div className="accordion-container">
          
                <Accordion defaultActiveKey="0">
                <p style={{
                            color:"black",
                            textAlign:'center',
                            fontSize: "28px",
                            fontWeight: "600",
                            textDecoration: "none",
                            lineHeight: "35px",
                        }}>Frequently Asked Questions</p>
                    <Card>
                       
                        <Accordion.Item eventKey="0">
                            <Accordion.Header className="accordian-header" >How many students can participate from our side?</Accordion.Header>
                            <Accordion.Body>
                            We think sessions with 30-50 students are ideal to give every student the opportunity to interact with the vyatra guides and particpating student peers from the destination country
        </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>What sort of infrastructure do we need at our end?</Accordion.Header>
                            <Accordion.Body>
                            Ideally each student is logged into the online meeting individually. However, if students are not able to use their own Laptops, then you will need to arrange in each classroom where students are gathered, 1. A laptop with a camera, microphone and good quality internet access, plus an electronic whiteboard or projector screen with good quality sound
                        </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>We would very much like to showcase another destination. Can you arrange this?</Accordion.Header>
                            <Accordion.Body>
                            Sure. Please reach out to rakesh@vyatra.com with your preference
                        </Accordion.Body>
                        </Accordion.Item>
                    </Card>
                </Accordion>
            </div>

            <Container>
                <div className="map-bg">
                    <Row>
                        <Col>
                            <Card className="map-card">
                                <h1 className="map-h1">We can help create your own custom journey</h1>
                                <p className="map-p">Do you have a virtual journey in mind? We have access to local guides all over the globe and can create a custom journey just for you.</p>
                                <button className="map-btn" onClick={handleNavigate}>Get In Touch</button>
                            </Card>
                        </Col>
                        <Col className="guide-col">
                            <div className="guide-container">
                                <div className="guide-card1">
                                    <img src={Kiran} alt="Kiran Singh" className="guide-image" />
                                    <div className="guide-info">
                                        <p className="local-guide">Local Guide</p>
                                        <p className="guide-name">Kiran Singh</p>
                                    </div>
                                </div>
                                <div className="guide-card2">
                                    <img src={Nihal} alt="Nihal Singh" className="guide-image" />
                                    <div className="guide-info">
                                        <p className="local-guide">Local Guide</p>
                                        <p className="guide-name">Nihal Singh</p>
                                    </div>
                                </div>
                                <div className="guide-card3">
                                    <img src={Jarad} alt="Jarad" className="guide-image" />
                                    <div className="guide-info">
                                        <p className="local-guide">Local Guide</p>
                                        <p className="guide-name">Jarad</p>
                                    </div>
                                </div>
                            </div>
                        </Col>

                    </Row>
                </div>
            </Container>

        </>
    );
}

export default HowWorks;
